@import "search-communities-list.less";
@import "search-results.less";

// TODO: Swap this var with ax-font var when ready
@_secondary-text-color: @ax-color-grey-60;

.search {
  margin: 0;

  .ax-breakpoint-m({
    margin: auto;
  });

  .search-bar-container {
    background-color: @ax-color-app-background;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 0;
    width: 100%;

    & * {
      min-width: 0;
    }

    .mobile-search-button-region {
      .ax-breakpoint-m--hidden();

      padding-right: @ax-spacing-m;
      margin: @ax-spacing-m auto;
      .menu-dropdown__popover {
        min-width: 23rem;
      }
    }

    .search-input-region {
      margin: @ax-spacing-s auto;
      max-width: @ax-page-width-s;
      padding: 0 @ax-spacing-m;

      .ax-breakpoint-l({
        max-width: @ax-page-width-l;
      });

      .search-input-view {
        height: 5.4rem;
        box-sizing: border-box;
        position: relative;

        .ax-breakpoint-m({
          margin: 0 @ax-spacing-m;
        });

        .ax-input input {
          padding-right: @ax-spacing-m;
        }
      }

      .btn-search {
        margin-left: @ax-spacing-s;

        .search-btn-text {
          display: none;

          .ax-breakpoint-m({
            display: inline-block;
          });
        }

        span {
          margin-right: 0;

          .ax-breakpoint-m({
            margin-right: @ax-spacing-s;
          });
        }
      }
    }

    .create-button-region-mobile {
      margin-right: @ax-spacing-m;
      padding: @ax-spacing-m 0;

      .ax-breakpoint-m({
        display: none;
      });

      #create-cancel.cp-modal__cancel {
        top: auto;
      }
    }
  }

  .tabs-region {
    margin-top: 2.0rem;
  }

  .tabbed-layout .tab-container {
    .ax-breakpoint-m({
      padding: 0 @ax-spacing-m;
    })
  }

  .tab-content-container {
    clear: left;
    margin: @ax-spacing-m 0;
    position: relative;

    .ax-breakpoint-m({
      margin: @ax-spacing-m @ax-gutter-page 9.6rem;
    })
  }

  // Styles for the Grid View
  .grid-view.article-search__view-type-selector {

    & .grid-view-button {
      color: @black;
      font-weight: @ax-font-weight-bold;
      line-height: normal;

      [class*="icon-"] {
        color: @primary-blue;
      }
    }

    * .list-view-button {
      color: @_secondary-text-color;
      font-weight: @ax-font-weight-regular;
      line-height: normal;

      [class*="icon-"] {
        color: @_secondary-text-color;
      }

      &:hover,
      &:focus {
        color: @primary-hover-blue;

        [class*="icon-"] {
          color: @primary-hover-blue;
        }
      }
    }

    .icon-list {
      font-weight: @ax-font-weight-bold;
    }
  }

  .grid-view .search-result__tile {
    .align-items(stretch);
    .justify-content(space-between);
    .size(100%);

    .base-card__wrapper {
      .flex-direction(column);

      &--whole {
        .flex-grow();
      }

      &--upper {
        .align-items(flex-start);
      }
    }

    .article-type-icon {
      margin: 10.2rem 0 0 calc(100% - 6rem);
    }

    .article-type-icon:not(.icon-training-article-nobg) {
      .article-type-icon-style-mixin(3.2rem);
    }

    .article-type-icon.icon-training-article-nobg {
      .article-type-icon-style-mixin(training, 2.8rem, 2.1rem, 0.6rem);
    }

    &.no-image .article-type-icon {
      margin-top: @ax-spacing-s;
    }

    .base-card__image {
      .flex(0 1 auto);
      .size(100%, auto);
      background: none;
      margin: 0;
      min-height: @ax-spacing-xl;

      img {
        height: 12.2rem;
        min-width: 100%;
        object-position: center;
      }
    }

    .search-result__lower {
      .flex-direction(column);
      .flex-grow();
      padding-top: 0;

      .star-container-region .star-container {
        display: flex;
      }
    }

    .description-region {
      .flex(1 1 auto);
      display: block;
    }

    .article-description {
      max-height: 4rem;
      max-width: 100%;
    }

    &.no-image .article-description {
      max-height: 10rem;
    }

    .extra-info-region {
      .align-self(flex-start);
      .flex(0 1 auto);
      margin-top: @ax-spacing-s;
      width: 100%;
    }

    .metadata-detail-item:first-child span {
      font-weight: @ax-font-weight-bold;
    }
  }

  .page-content {
    padding: @ax-spacing-m;

    .tabbed-content-wrapper {
      margin-top: 8rem;
    }

    .community-browse {
      .tabbed-content-wrapper {
        margin-top: @ax-spacing-xl;
      }

      .community-header-region {
        margin-top: 8rem;

        .ax-breakpoint-m({
          margin-top: 11rem;
          margin-left: @ax-spacing-l;
          margin-right: @ax-spacing-l;
        });

        .community-header-create-button-region{
          .ax-breakpoint-m--visible-flex();
        }

        .community-header__icon {
          .size(4.8rem);
          margin-right: @ax-spacing-m;

          .ax-breakpoint-m({
            .size(7.2rem);
          });

          img {
            max-height: 100%;
            .border-radius(@ax-border-radius-s);
          }

          &.icon-folder_open {
            padding: @ax-spacing-s;
            font-size: 3.2rem;

            .ax-breakpoint-m({
              padding: 1.2rem;
              font-size: 4.8rem;
            });

            background-color: @ax-color-grey-30;
            color: @ax-color-grey-60;
            .border-radius(@ax-border-radius-s);
          }
        }

        .community-breadcrumb {
          overflow: hidden;

          .all-crumb {
            color: inherit;
          }
        }

        .community-name {
          word-break: break-word;
          .ax-breakpoint-m-max({
            margin-right: @ax-spacing-s;
          });
        }

        .community-description {
          margin: 1rem 0;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          a {
            text-decoration: underline;
          }
        }

        .community-description-more {
          &__button {
            &.focus-visible:focus {
              .ax-focus-keyboard();
            }
            color: @primary-blue;
          }
        }

        .mobile-breadcrumb-flex {
          .flex(1 1 50%);
          .ax-breakpoint-m({
            .flex(1 1 0)
          });
        }

        .at-article-create-button {
          display: none;

          .ax-breakpoint-m({
            display: block;
            position: relative;
          })
        }
      }

      .timeline-card {
        min-height: 17rem; // allow space for the dropdown when you are a guest user
      }

      .page-control-bottom-region {
        .display-flex();
        max-width: @ax-page-width-timeline;
        .justify-content(center);
      }

      .page-control-top-region {
        display: none;
      }
    }
  }

  .create-button-region {
    .ax-breakpoint-m--visible-flex();

    .ax-breakpoint-m({
      position: relative;
    });

    button.focus-visible:focus {
      .ax-focus-keyboard();
    }

    .js-create-page-modal{
      top: 5rem;
    }

    margin-bottom: @ax-spacing-s;
  }

  .menu-reports-button__container {
    margin-left: @ax-spacing-s;
  }

  .menu-dropdown__container {
    margin-left: @ax-spacing-s;

    .context-menu-dropdown {
      background-color: @ax-color-white;

      &:hover {
        background-color: @ax-color-grey-30;
      }
    }

    .context-menu-dropdown-content {
      button {
        padding: @ax-spacing-s @ax-spacing-m;
      }
    }
  }
}

.article-search-wrapper {
  margin-top: @ax-spacing-m;
}

.community-context-button {
  .thick-gray-button();
}

.article-list-view,
.communities-settings,
.reports-view {
  .breadcrumb-title-wrapper {

    .header-breadcrumbs .main-crumbs button {
      color: @ax-color-grey-80;

      .ax-breakpoint-m({
        font-weight: @ax-font-weight-regular;
      });

      &.focus-visible:focus {
        .ax-focus-keyboard();
      }
    }

    .title-region {
      h2 {
        margin: 0;
        max-width: 14rem;

        .screen-sm-min({
          max-width: inherit;
        });

        .ax-breakpoint-m({
          font-size: 2.1rem;
        });
      }

      .community-title {
        .text-ellipsis();
        line-height: 1.2em;

        .ax-breakpoint-l({
          max-width: 40rem;
        });
      }
    }
  }
}

.tags-button-wrapper {
  .create-button-region {
    min-height: 4rem;
    padding-right: 0.3rem;
  }

  padding: 0;

  .ax-breakpoint-m({
    text-align: right;
    min-height: 240px; // this provides space for the "Add" menu dropdown
  });

  .search-by-tag-title {
    color: @ax-color-grey-80;
    display: none;
    font-size: 1.6rem;
    font-weight: @ax-font-weight-medium;
    padding-right: @ax-spacing-xs;

    .ax-breakpoint-m({
      display: block;
      padding-right: @ax-spacing-xs;
    });
  }

  .non-community-search-by-tag-title{
    padding-top: @ax-spacing-m;
  }

  ul {
    .ax-breakpoint-m({
      overflow-x: hidden;
      padding-right: @ax-spacing-xs;
      white-space: inherit;
    })
  }

  .tag {
    background-color: @ax-color-grey-20;
    border: none;
    margin: 0 0 0 @ax-spacing-s;
    line-height: 2rem;

    &.focus-visible:focus {
      .ax-focus-keyboard();
    }

    &.ax-button--ghost {
      background-color: transparent;
      border: 1px solid @primary-blue;
    }
  }

  .tag__selected {
    .border-radius(@ax-border-radius-m);
    background-color: @white;
    border-left-width: .4rem;
    border: .1rem solid @primary-blue;
  }

  a [class*="icon-"] {
    color: @primary-blue;
  }
}

.scrollable.vertical.overflow-hidden {
  overflow: hidden;

  .ax-breakpoint-m({
    overflow: auto;
  });
}

.question-create-modal #editor-page > #title {
  border-bottom: none;
}

.reports-mobile-container {
  a {
    color: inherit;
  }

  a [class*="icon-"] {
    opacity: 1;
  }
}

.community-description-modal .confirm-dialog__phrase {
  word-wrap: break-word;
}
