.channel-settings-layout {
  .image-crop .cropped-image-upload .preview-wrap.no-image {
    background-repeat: no-repeat;

    .thumbnail-preview {
      background-image: url('@common/assets/images/community_thumbnail_200.png');
      background-size: 200px;
    }

    .thumbnail-preview-size-1 {
      background-image: url('@common/assets/images/community_thumbnail_50.png');
      background-position: unset;
      background-size: 50px;
    }

    .thumbnail-preview-size-2 {
      background-image: url('@common/assets/images/community_thumbnail_40.png');
      background-position: unset;
      background-size: 40px;
    }
  }

  .group-button {
    margin-bottom: @ax-spacing-m;
  }

  .include-all-subteams {
    margin-top: @ax-spacing-l;
  }
}
