.squire-ui {
  .squire-toolbar-icon {
    font-size: 1.8rem;
    padding: 0 .2rem;
    color: @black;

    &:hover {
      color: black;
    }

    &.active {
      color: black;
    }
  }
}

.froala-editor {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-y;
  -ms-scroll-chaining: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  height: 100%;
  background: transparent;
  height: auto;

  .fr-video-embed-layer {
    textarea {
      height: auto;
    }
  }

  .fr-toolbar {
    ul.fr-dropdown-list li {
      list-style: none;
    }

    &.fr-top {
      .border-radius(0);
    }
  }

  z-index: 0;
}

.froala-editor,
.fr-box.fr-basic .fr-element {
  color: @ax-color-grey-70;
  font: inherit;
  font-family: inherit !important;
  font-size: inherit !important;

  &.fr-view {
    min-height: 25rem;
  }
}

.froala-editor:not(.training-module),
.fr-view {
  line-height: 1.6;
  cursor: text;
  padding: 0;
  margin: 0;

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  p {
    margin: 1rem 0;
  }

  h4,
  h5,
  h6 {
    margin: 0;
  }

  ol {
    margin: 0 1rem;
    padding: 0 1rem;
    list-style: decimal;

    li {
      margin: 0 1rem;
      padding: 0 1rem;
      list-style: decimal;
    }
  }

  ul {
    margin: 0 1rem;
    padding: 0 1rem;
    list-style: disc;

    li {
      margin: 0 1rem;
      padding: 0 1rem;
      list-style: initial;
      list-style-type: disc;
    }
  }

  blockquote {
    border-left: 2px solid blue;
    margin: 0;
    padding: 0 10px;
  }

  pre {
    margin: 0;
    overflow: auto;
    line-height: 1.4rem;
    .border-radius(.4rem);
    word-break: break-all;
    word-wrap: break-word;
    padding: @ax-spacing-s;
    color: @secondary-text-color;
    background-color: @secondary-bg;
    border: 0.1rem solid #CCCCCC;
    font-size: @h2-mobile-font-size;
  }

  figure {
    &.fr-dii {
      display: inline-block;
      margin: 0 1rem;
    }

    &.fr-dib {
      display: block;
    }
  }

  p {
    margin: 1rem 0;
  }

  .video-placeholder {
    width: 100%;
    max-width: 600px;
    height: 250px;
    background-color: #ececec;
    background-image: url('@training/assets/images/icn_type_video@2x.png');
    background-position: center center;
    background-repeat: no-repeat;
    line-height: 25rem;
    text-align: center;
    display: block;
  }

  .fr-img-caption.fr-bordered img {
    box-sizing: content-box;
  }

  .header {
    font-size: 2.3rem;
  }
}

.froala-editor {
  .video-upload-button {
    margin-top: @ax-spacing-s;
  }
}

.fr-dropdown-menu {
  margin: 0 !important;
}

.fr-toolbar .fr-command.fr-btn svg path,
.fr-popup .fr-command.fr-btn svg path,
.fr-modal .fr-command.fr-btn svg path {
  fill: @ax-color-text !important;
}

.fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown),
.fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) {
  background: @ax-color-grey-20;

  svg path {
    fill: @ax-color-blue-80 !important;
  }
}
