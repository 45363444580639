.tribute-container {
  .display-flex();
  .box-shadow(@ax-box-shadow-s);
  background: @ax-color-grey-10;
  max-height: 20rem;
  overflow-y: auto;

  .highlight {
    background: @ax-color-grey-20;

    &:hover {
      background: @ax-color-grey-20;
      cursor: pointer;
    }
  }
}

.user-mention-item {
  .display-flex();
  .align-items(center);
  padding: @ax-spacing-s;
  border: 1px solid @ax-color-grey-20;
}

.user-mention-avatar {
  .border-radius(50%);
  width: 4rem;
  height: 4rem;
  margin-right: @ax-spacing-s;
  .display-flex();
  .align-items(center);
  .justify-content(center);

  .user-mention-avatar-image {
    .border-radius(50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .user-mention-avatar-initials {
    color: @ax-color-white;
    font-size: @ax-font-size-xs;
    .display-flex();
    .align-items(center);
    .justify-content(center);
    width: 100%;
    height: 100%;
  }
}

.user-mention-name {
  font-size: @ax-font-size-xs;
  font-weight: @ax-font-weight-regular;
  color: @ax-color-grey-70;
  margin: 0;
}

.user-mention-details {
  span {
    font-size: @ax-font-size-xs;
    color: @ax-color-grey-60;
    margin: 0;
  }

  .user-mention-separator {
    font-weight: @ax-font-weight-bold;
    vertical-align: text-bottom;
  }
}

.mention-placeholder {
  padding: @ax-spacing-xxs;
}
