//determined by looking at the longest translated version of Back, which is currently HA-MY
@header-side-region-width: 10.1rem;

@logo-height-desktop: 5rem;
@logo-height: 3.5rem;

:root {
  --ax-app-header-logo-height-mobile: @logo-height;
  --ax-app-header-logo-height-desktop: @logo-height-desktop;

  --ax-app-header-button-gutter: @ax-spacing-s;

  .screen-lg-min({
    --ax-app-header-button-gutter: @ax-spacing-m;
  })
}

// Setup left/right gutters based on icon container size
@_header-gutter-profile: calc(var(--ax-app-header-button-gutter) - @ax-spacing-xxs);
@_header-gutter-back: calc(var(--ax-app-header-button-gutter) - @ax-spacing-xs);

#header {
  color: @ax-color-white;
  height: @header-height;
  border-bottom-width: .1rem;
  border-bottom-style: solid;
  box-sizing: content-box;

  .center-header {
    .display-flex(inline-flex);

    .screen-xs({
      display: none;
    });
  margin-right: 0;
}

>div {
  height: 100%;
}

.header-button {

  &.hover,
  &.active,
  &.focus-visible:focus {
    background-color: rgba(255, 255, 255, 0.2);
  }

  span {
    color: @ax-color-white;
  }
}

.logo {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  height: @logo-height;
  min-width: 100%;

  .ax-breakpoint-l({
    background-position: 0;
    min-width: 20rem;
  });
}

.right-header-region {
  min-width: max-content;

  .chat-menu-nav {
    .menu-item {
      padding: 0;
      justify-content: center;
      height: var(--ax-app-header-height-mobile);
      width: var(--ax-app-header-height-mobile);

      .ax-breakpoint-l({
        height: var(--ax-app-header-height-desktop);
        width: var(--ax-app-header-height-desktop);
      });

    .item-icon {
      margin: 0;
    }
  }
}
}

.menu-region {
  display: none;
}

.menu-overflow-region {
  display: none;
}

&.header-top {
  height: @header-height-desktop;

  .ax-container {
    .display-flex();
    .justify-content(space-between);
  }

  .left-header-region {
    position: static;
    margin-right: @ax-spacing-xs;

    .ax-breakpoint-l({
      display: none;
    });

  &.show-back-button {
    width: @header-side-region-width;
  }
}

.logo {
  background-position: 0;
  min-width: 20rem;
  margin-right: @header-spacing * 3;
  height: @logo-height-desktop;

  &.focus-visible:focus {
    .ax-focus-keyboard();
  }

  &.clickable:hover {
    cursor: pointer;
  }
}

.menu-region {
  .display-flex();
  .flex-grow(1);
  overflow: hidden;
}

.menu-overflow-region {
  display: block;
}
}

// if back button is not visible in mobile app,
// then we need to hide the left header region
// for logo to be on the left
.left-header-region:not(:has(.back-button-visible)) {
  .ax-breakpoint-s-max({
    display: none;
  });
}

.left-header-region.show-back-button:has(.back-button-visible) {
  .ax-breakpoint-s-max({
    display: block;
  });
}

// if back button is not visible in mobile app,
// then we need to move the logo to the left
.left-header-region:not(:has(.back-button-visible))~.center-header .logo {
  .ax-breakpoint-s-max({
    background-position: 20% 50%;
  });
}

.left-header-region.show-back-button:has(.back-button-visible)~.center-header .logo {
  .ax-breakpoint-s-max({
    background-position: 50% 50%;
  });
}

&.header-locked-to-top {
  height: @header-height;

  .logo {
    display: none;
  }

  .left-header-region {
    .flex(0 0 auto);
    width: auto;
    margin-right: 0;

    &.show-back-button {
      width: auto;
    }
  }

  .header-profile-dropdown-region {
    display: none;
  }

  .center-header {
    .flex(1 1 auto);
  }

  .menu-overflow-wrapper {
    padding: 0 @ax-spacing-s;

    .ax-breakpoint-l({
      padding: 0 @ax-spacing-m;
    });
}
}

.menu-overflow-wrapper {
  .display-flex();
  .align-items(center);
  padding: 0 @ax-spacing-l;

  .item-icon {
    font-size: 2.4rem;
    color: @ax-color-white;
    margin-right: @ax-spacing-s;
    opacity: 1;
  }

  .item-text {
    font-size: 1.5rem;
    white-space: nowrap;
    line-height: normal;
  }
}

.nav-drop-down-button {
  position: relative;
}

.nav-drop-down-menu {
  position: absolute;
}

@import 'headerDropdown/HeaderDropdown.less';
@import 'back/BackButton.less';
@import 'userProfileIcon/UserProfileIcon.less';
}
