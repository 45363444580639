.adjustable-modal.confirm-dialog-view {
  .confirm-dialog {
    text-align: center;

    &__icon {
      font-size: 5.2rem;
      margin: @ax-spacing-xl 0;
    }

    &__title {
      font-size: @ax-font-size-l;
      padding: 0 @ax-spacing-l;
      overflow-wrap: break-word;
    }

    &__phrase {
      font-size: @ax-font-size-s;
      padding: @ax-spacing-l;
      word-break: break-word;
    }
  }

  .actionbar .actioncontent,
  .actionbar.fixed .actioncontent {
    border-top: none;
    text-align: center;
    display: block;

    button {
      .ax-breakpoint-m({
        margin-right: @ax-spacing-s;
      });
    }
  }
}
