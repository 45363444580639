@fancy-image-prefix: /tb/axonify-client-web/src/main/html/common/components/axonFancyTree/assets/images/;
@import (reference) "@common/prerequisites/prerequisites.less";

.media-zoom.media-zoom--image * {
  pointer-events: none;
}

.media-zoom {
  .opacity(0);
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 0;
  overflow: hidden;

  &.media-zoom--video {
    .display-flex();
    .align-items(center);
    .justify-content(center);

    .media-zoom-wrapper {
      width: 100%;
    }
  }

  &.media-zoom--array {
    .media-zoom-wrapper {
      position: relative;
      padding: 0;
    }

    .media-zoom-region {
      display: inline-block;
      pointer-events: auto;

      * {
        pointer-events: none;
      }
    }

    .desktop-nav-buttons {
      display: none;

      .ax-breakpoint-m({
        display: block;

        .media-nav-prev,
        .media-nav-next {
          .button-reset-style();
          pointer-events: auto;
          position: fixed;
          top: 50%;
          transform: translateY(-50%);
          width: 4.5rem;
          height: 4.5rem;
          z-index: 1;
          transition: opacity 0.2s ease, transform 0.2s ease;
          background-color: @white;
          border-radius: 50%;
          .display-flex();
          .align-items(center);
          .justify-content(center);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

          span {
            color: @ax-color-grey-80;
            font-size: @ax-font-size-xl;
          }

          &:hover:not(.disabled) {
            transform: translateY(-50%) scale(1.05);
          }

          &.disabled {
            opacity: @ax-opacity-xl;
            cursor: default;
          }

          &.focus-visible:focus {
            outline: solid 3px @primary-blue;
            outline-offset: 2px;
          }
        }

        .media-nav-prev {
          left: @ax-spacing-l;
        }

        .media-nav-next {
          right: @ax-spacing-l;
        }
      })
  }

  // Desktop pagination dots
  .media-pagination {
    display: none;
    background-color: rgba(0,0,0, 0.5);
    border-radius: 3rem;

    .ax-breakpoint-m({
      .display-flex();
      .justify-content(center);
      position: fixed;
      bottom: 2rem;
      left: 0;
      right: 0;
      text-align: center;
      pointer-events: none;
      gap: @ax-spacing-s;
      z-index: 2;
      padding: 0.5rem 1rem;
      max-width: 80%;
      margin: 0 auto;
      width: fit-content;

      .pagination-dot {
        width: @ax-spacing-s;
        height: @ax-spacing-s;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.5);
        display: inline-block;
        transition: background-color 0.2s ease;

        &.active {
          background-color: @white;
          width: 1rem;
          height: 1rem;
          transform: translateY(-0.1rem);
        }
      }
    });
}

// Mobile controls container (navigation + pagination)
.mobile-controls-container {
  .display-flex();
  .align-items(center);
  .justify-content(center);
  position: fixed;
  bottom: 8rem;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0.5rem;
  width: auto;
  max-width: 90%;
  margin: 0 auto;

  .mobile-nav-prev,
  .mobile-nav-next {
    .button-reset-style();
    pointer-events: auto;
    width: @ax-spacing-xl;
    height: @ax-spacing-xl;
    z-index: 1;
    transition: opacity 0.2s ease, transform 0.2s ease;
    background-color: @white;
    border-radius: 50%;
    .display-flex();
    .align-items(center);
    .justify-content(center);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

    span {
      color: @ax-color-grey-80;
      font-size: @ax-font-size-m;
    }

    &.disabled {
      opacity: @ax-opacity-xl;
      cursor: default;
    }

    &.focus-visible:focus {
      outline: solid 2px @primary-blue;
      outline-offset: 2px;
    }
  }

  // Mobile pagination dots
  .mobile-pagination {
    text-align: center;
    pointer-events: none;
    .display-flex();
    .justify-content(center);
    gap: @ax-spacing-s;
    margin: 0 @ax-spacing-l;

    background-color: rgba(0,0,0, 0.5);
    border-radius: 3rem;
    padding: 0.5rem;

    .pagination-dot {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      display: inline-block;
      transition: background-color 0.2s ease;

      &.active {
        background-color: @white;
        width: @ax-spacing-s;
        height: @ax-spacing-s;
        transform: translateY(-0.1rem);
      }
    }
  }

  .ax-breakpoint-m({
    display: none;
  });
}
}

.media-zoom-wrapper {
  max-width: 100%;
  max-height: 100%;

  .media-zoom-region {
    background: black;
    .box-shadow(0px 0px 90px 30px rgba(0, 0, 0, 0.8));
  }

  .loading-wrapper-container .spinner-wrapper .spinner-blanket {
    background: black;
  }
}

.media-close-button {
  .button-reset-style();
  pointer-events: auto;
  .absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;

  span {
    color: darken(@white, 20%);
    font-size: 4rem;
    .text-stroke();

    &:hover {
      color: @white;
    }
  }

  &.focus-visible:focus {
    outline: solid 3px @primary-blue;
    outline: auto 5px @primary-blue;

    span {
      color: @white;
    }
  }
}
}
